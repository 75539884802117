import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)
// import i18n from './i18n'
import moment from 'moment'

import Notifications from 'vue-notification'
Vue.use(Notifications)



export default new Vuex.Store({
	state: {
		user: null,
		account: null,
		users: null,
		departments: null,
		// transactions: null,
		kudos: [],
		campaigns: null,
		messages: null,
		dashboards: null,
		portals: null,
		stats: null,
		isMobile: false,
		showDisconnected: null,
		showConfetti: false,
		
		kudoNotice: null,
		transactionNotice: null,
		transactionsFromWebSocket: [],
		
		wSConnectionLastPong: 0,
		wSConnectionPollingInterval: null,
		// showKudosGive: false,
	},
	
	getters: {
		getIsMobile: state => {
			return state.isMobile;
		},
		
		getShowDisconnected: state => {
			return state.showDisconnected;
		},
		
		getShowConfetti: state => {
			return state.showConfetti;
		},
		
		getKudoNotice: state => {
			return state.kudoNotice;
		},
		
		// getShowKudosGive: state => {
		// 	return state.showKudosGive;
		// },
		
		getTransactionNotice: state => {
			return state.transactionNotice;
		},
		
		getTransactionsFromWebSocket: state => {
			return state.transactionsFromWebSocket;
		},
		
		
		
		getAccount: state => {
			return state.account;
		},
		
		getUser: state => {
			return state.user;
		},
		
		getUsers: state => {
			if (!state.users || !state.users.length) {
				return;
			}
			
			return state.users
				.sort((a, b) => a.name && a.name.toLowerCase() > b.name && b.name.toLowerCase() ? 1 : -1);
		},
		
		getDepartments: state => {
			if (!state.departments || !state.departments.length) {
				return
			}
			
			return state.departments
				.filter( d => !d.deletedAt)
				.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
		},
		
		getAllDepartments: state => {
			if (!state.departments || !state.departments.length) {
				return
			}
			
			return state.departments
				.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
		},
		
		
		// getTransactions is not i use since it's from-to based
			// getTransactions: state => {
			//     if (!state.transactions || !state.transactions.length) {
			//         return
			//     }
			// 
			//     return state.transactions
			//         .filter( d => !d.deletedAt)
			//         .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
			// },
		
		getKudos: state => {
			if (!state.kudos.length) {
				return
			}
			
			return state.kudos;
		},
		
		getCampaigns: state => {
			if (!state.campaigns || !state.campaigns.length) {
				return
			}
			
			return state.campaigns
				.filter( d => !d.deletedAt)
				.sort((a, b) => b.startAt > a.startAt ? 1 : -1)
				.map( c => {
					const date = new Date();
					const start = new Date( c.startAt );
					const end = new Date( c.endAt );
					
					if (date > start && date < end) {
						c.status = 'ongoing'; //date is between the 2 dates
					} 
					else {
						c.status = 'notOngoing'; //date is not in the range
					}
					return c;
				})
				.map( c => {
					if (!c.type) {
						c.type = 'products';
					}
					
					return c;
				})
		},
		
		getMessages: state => {
			if (!state.messages || !state.messages.length) {
				return
			}
			
			return state.messages
				.sort((a, b) => b.updatedAt > a.updatedAt ? 1 : -1);
		},
		
		getDashboards: state => {
			if (!state.dashboards || !state.dashboards.length) {
				return
			}
			
			return state.dashboards
				.filter( d => !d.deletedAt)
				.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
		},
		
		getPortals: state => {
			if (!state.portals || !state.portals.length) {
				return
			}
			
			return state.portals
				.filter( p => !p.deletedAt)
				.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
		},
		
		getStats: state => {
			return state.stats;
		},
	},
	
	mutations: {
		setIsMobile(state, isMobile){
			state.isMobile = isMobile;
		},
		
		setShowDisconnected(state, showDisconnectedObject){
			state.showDisconnected = showDisconnectedObject;
		},
		
		setShowConfetti(state, showConfettiBoolean){
			state.showConfetti = showConfettiBoolean;
		},
		
		setKudoNotice(state, kudo){
			state.kudoNotice = kudo;
		},
		
		setKudoFromWebSocket(state, kudo){
			state.kudosFromWebSocket.push(kudo);
		},
		
		setTransactionNotice(state, transaction){
			state.transactionNotice = transaction;
		},
		
		setTransactionsFromWebSocket(state, transaction){
			state.transactionsFromWebSocket.push(transaction);
		},
		
		setUser(state, user){
			// console.log('mutation user', user);
			state.user = user;
			
			if (user) {
				analytics.identify( user.username, user.attributes);
			}
		},
		
		setAccount(state, account){
			state.account = Object.assign(
				{},
				account,
			);
			
			if (account) {
				const trackEmail = state.user?.attributes?.email ? state.user.attributes.email : 'unknown email';
				const trackData = {
					...account,
					text: '*setAccount* <https://upsellapp.io/admin/'+ state.account.accountId +'/home|'+state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(account, null, 4),
				};
				analytics.track( 'setAccount', trackData );
			}
		},
		
		updateAccount(state, account){
			state.account = account;
		},
		
		updateUser(state, user){
			state.users = state.users.map(u => {
				if (u.username == user.username) {
					u = user;
				}
				return u;
			});
		},
		
		setUsers(state, users){
			state.users = users;
		},
		
		setDepartments(state, departments){
			state.departments = departments;
		},
		
		updateDepartment(state, department){
			state.departments = state.departments.map(d => {
				if (d.departmentId == department.departmentId) {
					d = department;
				}
				return d;
			});
		},
		
		updateKudos(state, kudos){
			// console.log('updateKudos called', kudos);
			
			kudos.forEach( kudo => {
				if ( state.kudos.find( k => k.kudoId == kudo.kudoId) ) {
					// ignore it
					return null;
				}
				else {
					// insert it
					state.kudos.push( kudo );
				}
			});
			
		},
		
		
		// setTransactions is not i use since it's from-to based
			// setTransactions(state, transactions){
			//     state.transactions = transactions;
			// },
		
		// updateTransaction is not i use since it's from-to based
			// updateTransaction(state, transaction){
			//     state.transactions = state.transactions.map(t => {
			//         if (t.transactionId == transaction.transactionId) {
			//             t = transaction;
			//         }
			//         return t;
			//     });
			// },
		
		setStats(state, stats){
			state.stats = stats;
		},
		
		
		setCampaigns(state, campaigns){
			state.campaigns = campaigns;
		},
		
		updateCampaign(state, campaign){
			state.campaigns = state.campaigns.map(c => {
				if (c.campaignId == campaign.campaignId) {
					c = campaign;
				}
				return c;
			});
		},
		
		
		
		setMessages(state, messages){
			state.messages = messages;
		},
		
		updateMessage(state, message){
			state.messages = state.messages.map(m => {
				if (m.messageId == message.messageId) {
					m = message;
				}
				return m;
			});
		},
		
		deleteMessage(state, message){
			state.messages = state.messages.filter(m => m.messageId != message.messageId);
			// console.log('after delete:', state.messages);
		},
		
		setDashboards(state, dashboards){
			state.dashboards = dashboards;
		},
		
		updateDashboard(state, dashboard){
			state.dashboards = state.dashboards.map(r => {
				if (r.dashboardId == dashboard.dashboardId) {
					r = dashboard;
				}
				return r;
			});
		},
		
		setPortals(state, portals){
			state.portals = portals;
		},
		
		updatePortal(state, portal){
			state.portals = state.portals.map(p => {
				if (p.portalId == portal.portalId) {
					p = portal;
				}
				return p;
			});
		},
	},
	
	actions: { // methods... 
		async setAccount(context, account){
			console.log('setAccount', account);
			
			this.commit('setAccount', account);
			
			if (account) {
				// console.log('Account was dispatched', account);
				
				const accountPromises = [
					// context.dispatch('listUsers'),
					// context.dispatch('listDepartments'),
					// context.dispatch('listMessages', {method: 'listActiveOffers'}),
					// context.dispatch('listTrackings', {method: 'listLatest'}),
				];
				
				const promisesResult = await Promise.all( accountPromises );
				// console.log('setAccount promisesResult done');
				
				context.dispatch('webSocketConnect', account.accountId);
				
				return promisesResult;
			}
			else {
				console.log('no account was dispatched');
			}
		},
		
		webSocketConnect(context, accountId){
			try {
				console.log('webSocketConnect() called! ', accountId);
				// console.log(window.wSConnection);
				
				if (window.wSConnection && window.wSConnection.readyState === window.wSConnection.OPEN){
					// window.wSConnection = null;
					console.log('webSocketConnect is readyState AND OPEN');
				}
				else {
					console.log('webSocketConnect is OK to create new connection!');
				}
				
				
				if (context.state.wSConnectionPollingInterval) {
					clearInterval( context.state.wSConnectionPollingInterval );
				}
				
				const channelId = accountId;
				// const channelId = accountId || context.state.account.accountId;
				
				if (channelId) {
					// this.loading = 'connecting';
					// window.wSConnection = new WebSocket( process.env.VUE_APP_WS_ENDPOINT+'?channel='+channelId+'&username='+context.state.user.username);
					window.wSConnection = new WebSocket( process.env.VUE_APP_WS_ENDPOINT+'?channel='+channelId);
					// this.commit('setWSConnection', wSConnection);
					
					window.wSConnection.onopen = (event) => {
						const onOpenId = Math.round( Math.random()*1000 );
						console.log('webSocketConnect() onopen id '+onOpenId);
						// context.state.wSConnectionLastPong = new Date();
						// 
						// context.state.wSConnectionPollingInterval = setInterval( () => {
						//     console.log('onOpenId '+onOpenId);
						//     // how long since last context.state.wSConnectionLastPong?
						//     const millisecondsOld = new Date () - context.state.wSConnectionLastPong;
						//     console.log('millisecondsOld: ' +millisecondsOld);
						// 
						//     if ( millisecondsOld > (1000*60*10) ) { //1000*60*10 minutes
						//         window.wSConnection.close(1000, 'Pong is too old. Should refresh browser.');
						//         // clearInterval( context.state.wSConnectionPollingInterval );
						//         // this.commit('setShowRefresh', 'You have been disconnected from the server in over 1 minute.'); //'+Math.round(millisecondsOld/1000) +'
						//         return;
						//     }
						// 
						//     // window.wSConnection.send('ping');
						// }, 5000);
						
						context.state.wSConnectionLastPong = new Date();
						
						context.state.wSConnectionPollingInterval = setInterval( () => {
							// how long since last context.state.wSConnectionLastPong?
							// const millisecondsOld = new Date () - context.state.wSConnectionLastPong;
							// console.log('millisecondsOld: ' +millisecondsOld);
							// 
							// if ( millisecondsOld > (1000*60*10) ) { //1000*60*10 minutes
							//     window.wSConnection.close(1000, 'Pong is too old. Should refresh browser.');
							//     // clearInterval( context.state.wSConnectionPollingInterval );
							//     // this.commit('setShowRefresh', 'You have been disconnected from the server in over 1 minute.'); //'+Math.round(millisecondsOld/1000) +'
							//     return;
							// }
							if (window.wSConnection.readyState === window.wSConnection.OPEN) {
								// console.log('We think you are connected to websocket...');
							}
							else {
								console.log('webSocketSend closed! will try to reconnect with accountId or channelId: ');
								console.log('accountId', accountId);
								console.log('channelId', channelId);
								
								context.dispatch('webSocketConnect', channelId);
							}
							// window.wSConnection.send('ping');
						}, 1000 * 10);
					};
					
					window.wSConnection.onmessage = (event) => {
						// console.log('onmessage--->');
						// const data = event.data;
						// console.log(data);
						
						// if (event.data == 'pong') {
						//     context.state.wSConnectionLastPong = new Date();
						//     // new Audio('/assets/pong2.mp3').play(); 
						//     // https://notificationsounds.com/notification-sounds
						// }
						// else if (event.data == 'reconnect') {
						//     console.log('reconnect');
						//     window.wSConnection.close(1000, 'Got "reconnect" from pong');
						//     clearInterval( context.state.wSConnectionPollingInterval );
						//     context.dispatch('webSocketConnect');
						// }
						// else {
						//     context.dispatch('onWebSocketMessage', event);
						// }
						
						context.dispatch('onWebSocketMessage', event);
					};
					
					window.wSConnection.close = (code, reason) => {
						console.log('window.wSConnection.close');
						console.log(code);
						console.log(reason);
						
						// this.commit('setShowRefresh', 'You have been disconnected from the server. Will try to refresh');
						
						// const r = confirm("ok to reconnect?");
						// if (r == true) {
						//     console.log('will refresh!');
						// } 
						// clearInterval( context.state.wSConnectionPollingInterval );
						// context.dispatch('webSocketConnect');
						
						
						
						// clearInterval( context.state.wSConnectionPollingInterval );
						// alert('webSocket is closed');
						// console.log('window.wSConnection.close');
						// console.log(code);
						// console.log(reason);
						// 
						// if (window.wSConnection.readyState === WebSocket.OPEN) {
						//     console.log('already open?');
						// }
						// else {
						//     console.log('trying to reconnect webSocketConnect()');
						//     // context.dispatch('webSocketConnect');
						// }
					};
				}
			} 
			catch (err) {
				console.error('websocket connection failed...');
				console.error(err);
			}
		},
		
		onWebSocketMessage(context, event ){
			// console.log('onmessage event from websocket');
			// console.log(event);
			
			try {
				const data = JSON.parse(event.data);
				// console.log(data);
				
				if (!data.type) {
					return;
				}
				
				switch (data.type) {
					case 'createTransaction':
						// console.log('----createTransaction!');
						// console.log( data.content );
						
						context.commit('setTransactionsFromWebSocket', data.content);
						
						if ( !context.state.transactionNotice ) {
							context.commit('setTransactionNotice', data.content);
							
							setTimeout( () => {
								// self destruct!
								// console.log('----self destruct!');
								context.commit('setTransactionNotice', null);
							}, 5000);
						}
						else {
							console.log('// try again in 10 seconds');
							// try again in 10 seconds
							setTimeout( () => {
								console.log('transaction trying again...:', data.content);
								
								context.commit('setTransactionNotice', data.content);
								
								setTimeout( () => {
									// self destruct!
									context.commit('setTransactionNotice', null);
								}, 5000);
							}, 1000 * 10);
						}
						
						break;
					case 'createKudo':
						console.log('----createKudo!');
						console.log( data.content );
						
						const kudo = data.content;
						
						if (kudo.username == context.state.user?.username) {
							// console.log('kudo for you! ');
							
							if (kudo.from.user) {
								if ( !context.state.kudoNotice ) {
									context.commit('setKudoNotice', kudo);
									
									setTimeout( () => {
										// self destruct!
										context.commit('setKudoNotice', null);
									}, 5000 );
									// }, 5000);
								}
								else {
									// console.log('// try again in 10 seconds');
									// try again in 10 seconds
									setTimeout( () => {
										console.log('transaction trying again...:', kudo);
										context.commit('setKudoNotice', kudo);
										
										setTimeout( () => {
											// self destruct!
											context.commit('setKudoNotice', null);
										}, 5000);
									}, 1000 * 10);
								}
							}
						}
						
						break;
					default:
						console.log('data.type not recognized in onWebSocketMessage');
						console.log(data);
				}
				// this.showConfetti = true;
				// context.commit('setShowConfetti', true);
			}
			catch (e) {
				console.log('error in onWebSocketMessage');
				console.log(e);
			}
		},
		
		async updateAccount(context, account){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.accountId, account);
			
			if (response.data) {
				this.commit('updateAccount', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateAccount* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateAccount', trackData );
				
				return response.data;
			}
		},
		
		async deleteAccount(context){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+context.state.account.accountId);
			console.log( response.data );
			
			if (response.data) {
				this.commit('deleteAccount', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteAccount* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteAccount', trackData );
				
				return response.data;
			}
		},
		
		
		
		// users
		async listUsers(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users');
			
			if (response.data) {
				this.commit('setUsers', response.data);
			}
		},
		
		async updateUser(context, user){
			console.log('updateUser', user);
			// return;
			
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users/' + user.username, user);
			
			if (response.data) {
				console.log(response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateUser* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateUser', trackData );
				
				// this.commit('updateUser', response.data);
				return response.data;
			}
		},
		
		// async getUser(context, username){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users/' +username);
		// 
		//     if (response.data) {
		//         // console.log('response.data', response.data);
		//         this.commit('updateUser', response.data);
		//         return response.data;
		//     }
		// },
		
		async deleteUser(context, username){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users/' +username);
			
			if (response.data) {
				console.log('response.data', response.data);
				// this.commit('updateUser', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteUser* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteUser', trackData );
				
				return response.data;
			}
		},
		
		async resetPassword(context, user){
			console.log('resetPassword', user);
			
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/users/resetPassword', user);
			
			if (response.data) {
				console.log(response.data);
				
				
				
				
				
				/* 
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateUser* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateUser', trackData );
				*/
				// this.commit('updateUser', response.data);
				return response.data;
			}
		},
		
		
		
		
		
		
		
		
		
		
		
		// departments
		async listDepartments(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/departments');
			
			if (response.data) {
				this.commit('setDepartments', response.data);
			}
		},
		
		// async getDepartment(context, departmentId){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/departments/'+departmentId);
		// 
		//     if (response.data) {
		//         this.commit('updateDepartment', response.data);
		//         return response.data;
		//     }
		// },
		
		async createDepartment(context, department){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/departments', department);
			await context.dispatch('listDepartments');
			
			if (response.data) {
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createDepartment* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createDepartment', trackData );
				
				return response.data;
			}
		},
		
		async updateDepartment(context, department){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/departments/'+department.departmentId, department);
			
			if (response.data) {
				this.commit('updateDepartment', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateDepartment* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateDepartment', trackData );
				
				
				return response.data;
			}
		},
		
		async deleteDepartment(context, department){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/departments/' +department.departmentId);
			
			if (response.data) {
				// console.log('response.data', response.data);
				// this.commit('updateUser', response.data);
				this.commit('updateDepartment', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteDepartment* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteDepartment', trackData );
				
				
				return response.data;
			}
		},
		
		
		
		// Transaction
		async createTransaction(context, transaction){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions', transaction);
			
			if (response.data) {
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createTransaction* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail + "\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createTransaction', trackData );
				
				return response.data;
			}
		},
		
		async listTransactions(context, data){
			let response;
			
			if (data.departmentId) {
				// department
				response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions?departmentId='+data.departmentId+'&from='+data.from+'&to='+data.to);
			}
			else if (data.username) {
				// user
				response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions?username='+data.username+'&from='+data.from+'&to='+data.to);
			}
			else {
				// all...
				response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions?from='+data.from+'&to='+data.to);
			}
			
			if (response.data) {
				// this.commit('setTransactions', response.data);
				return response.data;
			}
		},
		
		// async getTransaction(context, transactionId){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions/'+transactionId);
		// 
		//     if (response.data) {
		//         // this.commit('updateTransaction', response.data);
		//         return response.data;
		//     }
		// },
		
		async updateTransaction(context, transaction){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions/'+transaction.transactionId, transaction);
			
			if (response.data) {
				// this.commit('updateTransaction', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateTransaction* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateTransaction', trackData );
				
				
				return response.data;
			}
		},
		
		async deleteTransaction(context, transaction){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/transactions/' +transaction.transactionId);
			
			if (response.data) {
				// this.commit('updateTransaction', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteTransaction* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteTransaction', trackData );
				
				
				return response.data;
			}
		},
		
		
		
		// Stats
		async listStats(context, data){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/stats');
			
			if (response.data) {
				this.commit('setStats', response.data);
				return response.data;
			}
		},
		
		async listStatsHistoric(context, data){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/stats?from='+data.from+'&to='+data.to+'&leaderboardPositions='+data.leaderboardPositions);
			
			if (response.data) {
				return response.data;
			}
		},
		
		
		
		// Kudos
		async createKudo(context, kudo){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/kudos', kudo);
			
			if (response.data) {
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createKudo* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail + "\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createKudo', trackData );
				
				if (response.data) {
					this.commit('updateKudos', [response.data]);
					return response.data;
				}
			}
		},
		
		async listKudos(context, data){
			console.log('listKudos', data);
			
			let response;
			
			if (data.departmentId) {
				// department
				response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/kudos?departmentId='+data.departmentId+'&from='+data.from+'&to='+data.to);
			}
			else if (data.username) {
				// user
				response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/kudos?username='+data.username+'&from='+data.from+'&to='+data.to);
			}
			else {
				response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/kudos?from='+data.from+'&to='+data.to);
			}
			
			if (response.data) {
				this.commit('updateKudos', response.data);
				return response.data;
			}
		},
		
		
		
		// Campaign
		async createCampaign(context, campaign){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/campaigns', campaign);
			await context.dispatch('listCampaigns');
			
			if (response.data) {
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createCampaign* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createCampaign', trackData );
				
				
				return response.data;
			}
		},
		
		async listCampaigns(context, data){
			let response;
			
			// if (data && data.departmentId) {
			//     const {departmentId, from, to} = data;
			//     response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/campaigns?departmentId='+departmentId+'&from='+from+'&to='+to);
			// }
			// else {
			// }
			response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/campaigns');
			// console.log('listCampaigns', data);
			
			if (response.data) {
				this.commit('setCampaigns', response.data);
				return response.data;
			}
		},
		
		// async getCampaign(context, campaignId){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/campaigns/'+campaignId);
		// 
		//     if (response.data) {
		//         this.commit('updateCampaign', response.data);
		//         return response.data;
		//     }
		// },
		
		async updateCampaign(context, campaign){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/campaigns/'+campaign.campaignId, campaign);
			
			if (response.data) {
				this.commit('updateCampaign', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateCampaign* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateCampaign', trackData );
				
				return response.data;
			}
		},
		
		async deleteCampaign(context, campaign){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/campaigns/' +campaign.campaignId);
			
			if (response.data) {
				this.commit('updateCampaign', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteCampaign* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteCampaign', trackData );
				
				
				return response.data;
			}
		},
		
		
		
		// Message
		async createMessage(context, message){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages', message);
			await context.dispatch('listMessages');
			
			if (response.data) {
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createMessage* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createMessage', trackData );
				
				return response.data;
			}
		},
		
		async listMessages(context, data){
			let response;
			
			response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages');
			// console.log('listMessages', data);
			
			if (response.data) {
				this.commit('setMessages', response.data);
				return response.data;
			}
		},
		
		async updateMessage(context, message){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages/'+message.messageId, message);
			
			if (response.data) {
				this.commit('updateMessage', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateMessage* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateMessage', trackData );
				
				return response.data;
			}
		},
		
		async deleteMessage(context, message){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/messages/' +message.messageId);
			
			if (response.data) {
				this.commit('deleteMessage', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteMessage* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteMessage', trackData );
				
				return response.data;
			}
		},
		
		
		
		
		
		// dashboards
		async listDashboards(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/dashboards');
			
			if (response.data) {
				this.commit('setDashboards', response.data);
			}
		},

		// async getDashboard(context, dashboardId){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/dashboards/'+dashboardId);
		// 
		//     if (response.data) {
		//         this.commit('updateDashboard', response.data);
		//         return response.data;
		//     }
		// },

		async createDashboard(context, dashboard){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/dashboards', dashboard);
			await context.dispatch('listDashboards');
			
			if (response.data) {
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createDashboard* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createDashboard', trackData );
				
				
				return response.data;
			}
		},

		async updateDashboard(context, dashboard){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/dashboards/'+dashboard.dashboardId, dashboard);
			
			if (response.data) {
				this.commit('updateDashboard', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updateDashboard* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updateDashboard', trackData );
				
				
				return response.data;
			}
		},

		async deleteDashboard(context, dashboard){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/dashboards/' +dashboard.dashboardId);
			
			if (response.data) {
				this.commit('updateDashboard', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deleteDashboard* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deleteDashboard', trackData );
				
				
				return response.data;
			}
		},

		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		

		// portals
		async listPortals(context){
			const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/portals');
			
			if (response.data) {
				this.commit('setPortals', response.data);
			}
		},

		// async getPortal(context, portalId){
		//     const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/portals/'+portalId);
		// 
		//     if (response.data) {
		//         this.commit('updatePortal', response.data);
		//         return response.data;
		//     }
		// },
		
		async createPortal(context, portal){
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/portals', portal);
			await context.dispatch('listPortals');
			
			if (response.data) {
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*createPortal* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'createPortal', trackData );
				
				
				return response.data;
			}
		},
		
		async updatePortal(context, portal){
			const response = await axios.put(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/portals/'+portal.portalId, portal);
			
			if (response.data) {
				this.commit('updatePortal', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*updatePortal* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'updatePortal', trackData );
				
				
				return response.data;
			}
		},

		async deletePortal(context, portal){
			const response = await axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/portals/' +portal.portalId);
			
			if (response.data) {
				this.commit('updatePortal', response.data);
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*deletePortal* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'deletePortal', trackData );
				
				
				return response.data;
			}
		},
		
		async checkAccessCode(context, accessCode){
			console.log('checkPortalAccessCode', accessCode);
			const response = await axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+context.state.account.accountId+'/portals/checkAccessCode', {accessCode});
			
			if (response.data) {
				
				const trackEmail = context.state.user?.attributes?.email ? context.state.user.attributes.email : 'unknown email';
				const trackData = {
					 ...response.data,
					 text: '*checkAccessCode* <https://upsellapp.io/admin/'+ context.state.account.accountId +'/home|'+context.state.account.name+'> by '+trackEmail, //+"\n" + JSON.stringify(response.data, null, 4),
				};
				analytics.track( 'checkAccessCode', trackData );
				
				
				return response.data;
			}
		},
		
	}
})
