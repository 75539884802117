<template>
    <div class="fixed inset-0 flex items-center justify-center">
        <div class="grow-animation rounded-full w-64 h-64 bg-green flex items-center justify-center">
            <i 
                class="fas fa-thumbs-up text-white leading-none" 
                style="font-size: 8rem;"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueConfetti from 'vue-confetti';

Vue.use(VueConfetti);

export default {
    methods: {
        start() {
            this.$confetti.start();
        },

        stop() {
            this.$confetti.stop();
        },

        love() {
            this.$confetti.update({
                particles: [
                    {
                        type: 'heart',
                    },
                ],
                defaultSize: 20,
                defaultColors: [
                    'green', 
                ],
            });
        }
    },
    
    mounted(){
        this.start();
        
        this.$confetti.update({
            particles: [
                // {
                //     type: 'heart',
                // },
                // {
                //     type: 'circle',
                // },
                {
                    type: 'rect',
                },
                // {
                //     type: 'image',
                //     url: 'https://clipart.info/images/ccovers/1559759850blue-light-png-circle.png',
                // },
            ],
            defaultSize: 18,
            defaultColors: [
                'OliveDrab', 
                'Gold', 
                'Pink', 
                'SlateBlue', 
                'LightBlue', 
                'Violet', 
                'PaleGreen', 
                'SteelBlue', 
                'SandyBrown', 
                'Chocolate', 
                'Crimson'
            ],
            windSpeedMax: 2,
            defaultDropRate: 10,
        });
        
        setTimeout( () => {
            this.stop();
            this.$store.commit('setShowConfetti', false);
        }, 2000);
    }
}
</script>


<style lang="scss">
    .grow-animation {
        animation: grow 2s ease-in-out forwards;
        z-index: 1000;
    }
    
    @keyframes grow { 
        0% { 
            transform: scale(0); 
        }
        10% { 
            transform: scale(2); 
        } 
        20% { 
            transform: scale(.75); 
        } 
        90% { 
            transform: scale(1); 
            opacity: 1;
        } 
        100% { 
            transform: scale(5); 
            opacity: 0;
        } 
    }

</style>