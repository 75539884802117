<template>
    <nav class="flex space-x-4">
        <div 
            v-for="(tab, index) in tabs"
            :key="tab.alias"
            @click="onClick(tab)"
            class=" py-4 cursor-default"
            :class="[ active == tab.alias ? 'border-b-2 border-green' : ' border-b-2 border-transparent']">
            
            {{tab.name}}
        </div>
    </nav>
</template>

<script>
    export default {
        props: [
            'tabs',
            'active',
        ],
        
        methods: {
            onClick( tab ){
                if (tab.route) {
                    this.$router.push( tab.route );
                }
                else {
                    this.$emit('click', tab);
                }
            }
        }
    }
</script>
