<template>
    <div 
        class="
            flex-none flex h-32 bg-beige
        "
        :class="classes">
        
        <div 
            v-if="hasLeftSlot || hasLeftSlot"
            class="flex-none w-12 h-12 flex items-center justify-end">
            
            <slot name="left"></slot>
        </div>
        <div class="flex-grow text-center md:text-left md:px-12">
            <slot></slot>
        </div>
        <div 
            v-if="hasLeftSlot || hasRightSlot"
            class="flex-none w-12 h-12 flex items-center justify-start">
            
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            hasLeftSlot () {
                return !!this.$slots['left'];
            },
            hasRightSlot () {
                return !!this.$slots['right'];
            }
        },
        
        props: {
            classes: {
                default: 'items-center',
            },
        },
        
        data() {
            return {
                
            }
        },
    }
</script>