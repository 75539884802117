import Vue from 'vue'
import Router from 'vue-router'

import Store from './store'
import {Auth} from '@aws-amplify/auth';

Vue.use(Router)

Vue.use(Store)

import Axios from 'axios'
Vue.prototype.$axios = Axios;

const routes = [
	// www promo
	{
		path: '/',
		name: 'www',
		component: () => import('./views/www/Index.vue'), 
		meta: { category: 'landingPage'}
	},
	
	// signup
	{
		path: '/signup',
		name: 'signup',
		component: () => import('./views/authentication/SignUp.vue'), 
		meta: { category: 'account'}
	},
	
	// signin
	{
		path: '/signin',
		name: 'signin',
		component: () => import('./views/authentication/SignIn.vue'), 
		meta: { category: 'account'}
	},
	
	// signout
	{
		path: '/signout',
		name: 'signout',
		component: () => import('./views/authentication/SignOut.vue'), 
		meta: { category: 'account'}
	},
	
	// resetpassword
	{
		path: '/resetpassword',
		name: 'resetpassword',
		component: () => import('./views/authentication/ResetPassword.vue'), 
		meta: { category: 'account'}
	},
	
	// resetpassword
	{
		path: '/newpasswordrequired',
		name: 'newpasswordrequired',
		component: () => import('./views/authentication/NewPasswordRequired.vue'), 
		meta: { category: 'account'}
	},
	
	// // authentication callbacks from social connections and stuff
	// {
	//     path: '/authentication/callback',
	//     name: 'authentication',
	//     component: () => import('./views/authentication/Callback.vue'), 
	//     meta: { category: 'Account', publicPage: true }
	// },
	
	{
		path: '/starturl',
		name: 'StartUrl',
		component: () => import('./views/authentication/StartUrl.vue'), 
		meta: { category: 'StartUrl' }
	},
	






	// User Logged in
	// Now, Choose account
	{
		path: '/accounts',
		name: 'accounts',
		component: () => import('./views/authentication/Accounts.vue'), 
		meta: { category: 'account', privatePage: true, }
	},
	
	// User Logged in custom:superadmin
		{
			path: '/superadmin/home',
			name: 'superadmin-home',
			component: () => import('./views/superadmin/Home.vue'), 
			meta: { category: 'superadmin', privatePage: true, adminPage: true}
		},
		
	// User Logged in
		// Account is chosen. Admin pages
		{
			path: '/admin/:accountId/home',
			name: 'home',
			component: () => import('./views/admin/home/Index.vue'), 
			meta: { category: 'home', privatePage: true, adminPage: true}
		},
		{
			path: '/admin/:accountId/departments',
			name: 'departments',
			component: () => import('./views/admin/departments/Index.vue'), 
			meta: { category: 'departments', privatePage: true, adminPage: true}
		},
		{
			path: '/admin/:accountId/access/:section?',
			name: 'access',
			component: () => import('./views/admin/access/Index.vue'), 
			meta: { category: 'access', privatePage: true, adminPage: true}
		},
		{
			path: '/admin/:accountId/campaigns/:campaignId?',
			name: 'campaigns',
			component: () => import('./views/admin/campaigns/Index.vue'), 
			meta: { category: 'campaigns', privatePage: true, adminPage: true}
		},
		{
			path: '/admin/:accountId/dashboards',
			name: 'dashboards',
			component: () => import('./views/admin/dashboards/Index.vue'), 
			meta: { category: 'dashboards', privatePage: true, adminPage: true}
		},
		{
			path: '/admin/:accountId/messages',
			name: 'messages',
			component: () => import('./views/admin/messages/Index.vue'), 
			meta: { category: 'messages', privatePage: true, adminPage: true}
		},
		{
			path: '/admin/:accountId/settings',
			name: 'settings',
			component: () => import('./views/admin/settings/Index.vue'), 
			meta: { category: 'settings', privatePage: true, adminPage: true}
		},
		
		
		
		
		// Account is chosen! User is not admin
		{
			path: '/app/:accountId/home',
			name: 'app-home',
			component: () => import('./views/app/Home.vue'), 
			meta: { category: 'home', privatePage: true, appPage: true}
		},
		{
			path: '/app/:accountId/departments',
			name: 'app-departments',
			component: () => import('./views/app/Departments.vue'), 
			meta: { category: 'departments', privatePage: true, appPage: true}
		},
			{
				path: '/app/:accountId/departments/:departmentId/transactions',
				name: 'app-transactions',
				component: () => import('./views/app/Transactions.vue'), 
				meta: { category: 'transactions', privatePage: true, appPage: true}
			},
		{
			path: '/app/:accountId/user',
			name: 'app-user',
			component: () => import('./views/app/User.vue'), 
			meta: { category: 'user', privatePage: true, appPage: true}
		},
			
			
		
			
		// PORTAL
		{
			path: '/portal/signin',
			name: 'portal-signin',
			component: () => import('./views/portal/SignIn.vue'), 
			meta: { category: 'portal'}
		},
			
			{
				path: '/portal/:accountId/home',
				name: 'portal-home',
				component: () => import('./views/portal/Home.vue'), 
				meta: { category: 'portal', portalPage: true}
			},
			
			{
				path: '/portal/:accountId/users',
				name: 'portal-users',
				component: () => import('./views/portal/Users.vue'), 
				meta: { category: 'portal', portalPage: true}
			},
			
			{
				path: '/portal/:accountId/users/:username/departments',
				name: 'portal-departments',
				component: () => import('./views/portal/Departments.vue'), 
				meta: { category: 'portal', portalPage: true}
			},
			
				{
					path: '/portal/:accountId/users/:username/departments/:departmentId/transactions',
					name: 'portal-transactions',
					component: () => import('./views/portal/Transactions.vue'), 
					meta: { category: 'portal', portalPage: true}
				},
				
				// {
				//     path: '/app/:accountId/departments/:departmentId/transactions',
				//     name: 'app-transactions',
				//     component: () => import('./views/app/transactions/Index.vue'), 
				//     meta: { category: 'transactions', privatePage: true, appPage: true}
				// },
				
				
				
				
			// {
			//     path: '/portal/:accountId/departments',
			//     name: 'portal-departments',
			//     component: () => import('./views/app/departments/Index.vue'), 
			//     meta: { category: 'departments', appPage: true}
			// },
			//     {
			//         path: '/portal/:accountId/departments/:departmentId/transactions',
			//         name: 'portal-transactions',
			//         component: () => import('./views/app/transactions/Index.vue'), 
			//         meta: { category: 'transactions', appPage: true}
			//     },
		
		
			
		// dashboardId
		{
			path: '/dashboard/:accessToken',
			name: 'dashboard-show',
			component: () => import('./views/dashboard/Show.vue'), 
			meta: { category: 'dashboard', privatePage: false}
		},
]

const router = new Router({
	mode: 'history', // default commented out
	// base: process.env.BASE_URL,
	routes: routes
});

router.beforeEach(async (to, from, next) => {
	// console.log('Store.getters.setUser', Store.getters.getUser);
	
	if ( to.matched.some(record => record.meta.privatePage) ){
		// console.log('route privatePage = TRUE');
		
		if (! Store.getters.getUser ){
			try {
				// console.log('user not set');
				
				const user = await Auth.currentAuthenticatedUser();
				// Store.state.signedIn = true;
				
				const jwt = user
					.getSignInUserSession()
					.getIdToken()
					.getJwtToken();
				
				Store.commit('setUser', user);
				// console.log('setUser user    ', user);
				
				// Store.commit('setAuthorizationHeader', jwt);
				
				// console.log('--- Segment --- Identify user', user.username, user.attributes.email );
				
				// Get the active account from the url IF the State is empty.
				if ( 
					to.params.accountId && 
					!Store.getters.getAccount){ //&& !Store.state.account.accountId
					
					const accountId = to.params.accountId;
					// console.log('accountId', accountId);
					
					// fetch account!
					const response = await Axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+accountId);
					if (!response.data) {
						throw Error('Could not connect to this account');
					}
					else {
						// console.log('response.data accountId', response.data);
					}
					const account = response.data;
					
					// set account!
					// Store.commit('setAccount', account);
					
					await Store.dispatch('setAccount', account);
					
					// // get account!
					// const currentAccount = Store.getters.getAccount;
					// console.log('currentAccount', currentAccount);
					
					// const response_account = await Axios.get(Store.state.apiEndpoint + '/accounts/' + accountId);
					// console.log('currentAccount', currentAccount);
					
					// // console.log('Account Login router.js');
					// // console.log('state accountBrandKit should be reset so it can be refetched!');
					// // console.log('----> state accountBrandKit',Store.state.accountBrandKit );
					// 
					// // Store.state.account = response_account.data.account;
					// const account = response_account.data.account;
					// Store.commit('setAccountGeneral', account);
					// 
					// // Store.state.account.users = response_account.data.users;
					// const users = response_account.data.users;
					// Store.commit('setAccountUsers', users);
				
					// Store.state.usersAccounts.find( a => {
					//     if (a.accountId == accountId) {
					//         Store.state.account.accountName = a.accountName;
					//     }
					// })
				
					// console.log('--- Segment --- Group accountId:', accountId, Store.state.account.accountName);
					// await window.analytics.group( accountId, Store.state.account);
				}
				
				// // console.log('--- Segment --- Identify user', user.username, user.attributes.email );
				// // await window.analytics.identify( user.username, user.attributes);
				// 
				// // Get all the account that this user is participating in
				// const response = await Axios.get(Store.state.apiEndpoint + '/account/usersAccounts');
				// Store.state.usersAccounts = response.data;
				// 
				// // Get the active account from the url IF the State is empty.
				// if ( to.params.accountId && !Store.state.account.accountId){
				//     const accountId = to.params.accountId;
				//     const response_account = await Axios.get(Store.state.apiEndpoint + '/account/accounts/' + accountId);
				// 
				//     // console.log('Account Login router.js');
				//     // console.log('state accountBrandKit should be reset so it can be refetched!');
				//     // console.log('----> state accountBrandKit',Store.state.accountBrandKit );
				// 
				//     // Store.state.account = response_account.data.account;
				//     const account = response_account.data.account;
				//     Store.commit('setAccountGeneral', account);
				// 
				//     // Store.state.account.users = response_account.data.users;
				//     const users = response_account.data.users;
				//     Store.commit('setAccountUsers', users);
				// 
				//     // Store.state.usersAccounts.find( a => {
				//     //     if (a.accountId == accountId) {
				//     //         Store.state.account.accountName = a.accountName;
				//     //     }
				//     // })
				// 
				//     // console.log('--- Segment --- Group accountId:', accountId, Store.state.account.accountName);
				//     // await window.analytics.group( accountId, Store.state.account);
				// }
				// next()
			} 
			catch (err) {
				console.error(err);
				Store.commit('setUser', null);
				// Store.commit('setAccount', null);
				await Store.dispatch('setAccount', null);
				
				next({
					path: '/signin'
				});
			}
		}
		
		// next()
		
		
		
		if ( 
			to.params.accountId && 
			Store.getters.getAccount ){
			
			const accountId = to.params.accountId;
			const user = Store.getters.getUser;
			
			if (user.attributes.zoneinfo) {
				const departments = JSON.parse( user.attributes.zoneinfo );
				
				// console.log( departments[accountId] );
				
				// if (departments[accountId] == 'admin') {
				//     console.log('YOU ARE ADMIN');
				// }
				// else if (departments[accountId] == 'admin') {
				// 
				// }
			}
		}
	}
	
	else if ( to.matched.some(record => record.meta.portalPage) ){
		// console.log('route portalPage = TRUE');
		
		// Get the active account from the url IF the State is empty.
		
		
		if ( 
			to.params.accountId && 
			!Store.getters.getAccount) {
			
			try {
				if ( !localStorage.accessToken || !localStorage.portal ) {
					throw Error('Did not find accessToken or portal in localStorage. Goto sign in page');
				}
				
				const accessToken = localStorage.accessToken;
				const portal = JSON.parse( localStorage.portal );
				
				const account = {
					accountId: portal.accountId,
					portal: portal,
					accessToken: accessToken,
				};
				
				console.log('account it for portal!', account);
				
				await Store.dispatch('setAccount', account);
			} 
			catch (err) {
				console.error(err);
				await Store.dispatch('setAccount', null);
				next({name: 'portal-signin'});
			}
		}
	}
	else {
		// console.log('requiresSignin FALSE');
		// next()
	}
	
	
	
	// finally go next()
	next()
})

router.afterEach( async (to, from) => {
	// page tracker
	analytics.page( to.name , { 
		path: to.path,
		category: to.meta && to.meta.category ? to.meta.category : null,
	});
})



router.onReady( async (to, from) => {
	// if (to.query && to.query.embedParams) {
	//     const embedParams = JSON.parse( decodeURIComponent( to.query.embedParams ) );
	//     Store.state.embedParams = embedParams;
	//     // console.log('embedParams: ', embedParams);
	// }
	// 
	// // when router is ready, be sure to fetch the brandkit from AccountsMixins (router.app = VUE instance)
	// if (Store.state.signedIn) {
	//     await router.app.brandKitReset();
	//     await router.app.brandKitFetch();
	// }
})

export default router;
