import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import '@/assets/css/styles.scss'

import { Amplify } from '@aws-amplify/core';

import {Auth} from '@aws-amplify/auth';
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);

// your Cognito Hosted UI configuration
const oauth = {
    domain: process.env.VUE_APP_AUTH_DOMAIN,//'brandapp.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid'],
    redirectSignIn: process.env.VUE_APP_AUTH_REDIRECTSIGNIN, //'https://localhost:8080/authentication/callback/',
    redirectSignOut: process.env.VUE_APP_AUTH_REDIRECTSIGNOUT, //'https://localhost:8080/',
    responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : false
    },
};

Auth.configure({ oauth });
Vue.config.productionTip = false

// import i18n from './i18n'

const moment = require('moment');
require('moment/locale/en-gb');
require('moment/locale/nb');
Vue.use(require('vue-moment'), {moment});

import Notifications from 'vue-notification'
Vue.use(Notifications)

import Axios from 'axios'
Vue.prototype.$axios = Axios;

Axios.interceptors.request.use( (config) => {
    if ( config.url.startsWith( process.env.VUE_APP_API_ENDPOINT ) ) {
        // console.log('startsWith api path...');
        
        return Auth.currentSession()
            .then(session => {
                // console.log('currentSession', session);
                // User is logged in. Set auth header on all requests pointing to the API
                config.headers.Authorization = session.idToken.jwtToken
                // console.log('axios config: ', config);
                return Promise.resolve(config)
            })
            .catch( (e) => {
                // console.log(' No logged-in user', e);
                // No logged-in user: don't set auth header
                return Promise.resolve(config)
            })
    }
    
    return config;
});

Axios.interceptors.response.use(
    response => {
        return response;
    }, 
    async error => {
        // Axios do not default respond with data object if server side error (400)
        throw Error( error.response.data.message || error.response.data );
    }
);

import VTooltip from 'v-tooltip';
Vue.use(VTooltip);
VTooltip.options.defaultDelay = 250;
// VTooltip.options.popover.defaultTrigger = 'hover focus click';


// Global Mixin
    import AccountMixins from '@/mixins/AccountMixins';
    Vue.mixin(AccountMixins);

// Global Components
    import Modal from '@/components/Modal.vue';
    Vue.component('Modal', Modal);
    
    import Tabs from '@/components/Tabs.vue';
    Vue.component('Tabs', Tabs);
    
    import Header from '@/components/Header.vue';
    Vue.component('Header', Header);
    
    import SvgIcon from '@/components/SvgIcon.vue';
    Vue.component('SvgIcon', SvgIcon);
    
    import Confetti from '@/components/Confetti.vue';
    Vue.component('Confetti', Confetti);
    
// VUE Init
new Vue({
    store,
    router,
    render: h => h(App),
    async mounted() {
        document.title = process.env.VUE_APP_TITLE +' v' + process.env.VUE_APP_VERSION;
		if (analytics) {
            analytics.load( process.env.VUE_APP_SEGMENT_KEY );
        }
    }
}).$mount('#app')
