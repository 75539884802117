// ApiMixins.js

export default {
	components: {
		'InputUpload': () => import('@/components/input/InputUpload.vue'),
		'InputSelect': () => import('@/components/input/InputSelect.vue'),
		'InputMultiline': () => import('@/components/input/InputMultiline.vue'),
		'InputSingleline': () => import('@/components/input/InputSingleline.vue'),
	},
	
	data() {
		return {
			industriesArray: {
				hotel: 'Hotel',
				restaurant: 'Restaurant',
				other: 'Øvrig',
			}
		}
	},
	
	computed: {
		accountId() {
			if (this.$store.getters.getAccount) {
				return this.$store.getters.getAccount.accountId;
			}
			return false;
		},
		
		account(){
			return this.$store.getters.getAccount;
		},
		
		signedInUser(){
			return this.$store.getters.getUser;
		},
		
		isMobile(){
			return this.$store.getters.getIsMobile;
		},
		
		isSuperAdmin(){
			if ( 
				this.signedInUser && 
				this.signedInUser.attributes['custom:superadmin'] == 'true') {
				
				return true;
			}
			
			return false;
		},
		
		signedInUserRole(){
			// if a newly signed up Upsell account is done, then the zoneinfo is not set in the user token. 
			// so check the owner of the new account if it's the same as the user.
			if (
				this.signedInUser && 
				this.signedInUser.username && 
				this.account &&
				this.account.owner &&
				this.account.owner.username &&
				this.signedInUser.username == this.account.owner.username ) {
				
				return 'admin';
			}
			// returns either 'admin' or an array of departmentIds
			else if (this.signedInUser && this.signedInUser.attributes.zoneinfo) {
				// console.log('this.signedInUser.attributes.zoneinfo');
				const departments = JSON.parse( this.signedInUser.attributes.zoneinfo );
				return departments[ this.accountId ];
			}
			return
		},
		
		signedInUserDepartments(){
			// console.log('signedInUserDepartments', this.signedInUserRole);
			
			const departments = this.$store.getters.getDepartments;
			
			if (!departments) {
				return
			}
			
			if (this.isSuperAdmin) {
				return departments;
			}
			else if (this.signedInUserRole == 'admin') {
				return departments;
			}
			else {
				return departments.filter( d => this.signedInUserRole.includes( d.departmentId ) );
			}
		},
		
		version(){
			return process.env.VUE_APP_VERSION;
		},
	},
	
	methods: {
		// accounts
		async accountsCreate( account ) {
			const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/accounts', account);
			return response.data;
		},
		
		async accountsList() {
			const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts');
			return response.data;
		},
		
		async accountsGet( accountId ) {
			const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT + '/accounts/'+accountId);
			return response.data;
		},
		
		
		async accountUsersInvite( data ) {
			if (
				data.invitation.setCustomPassword &&
				!data.invitation.temporaryPassword || data.invitation.temporaryPassword == '' ) {
				
				throw Error( 'For å invitere kreves et gyldig passord på minst 6 tegn' );
			}
			
			if (
				data.invitation.phone_number && 
				data.invitation.phone_number != '' ) {
				
				data.invitation.phone_number = data.invitation.phone_number.replace(/\s/g, "");
			}
			
			const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/'+ this.accountId +'/users/invite', data);
			return response.data;
		},
		
		
		currencyFormat( number ){
			return new Intl.NumberFormat('nb-NB').format( number );
			// return new Intl.NumberFormat('nb-NB', { style: 'currency', currency: 'NOK' , currencyDisplay: 'narrowSymbol' }).format( number );
		},
		
		
		
		humanFileSize(bytes, si = false, dp = 1) {
			const thresh = si ? 1000 : 1024;

			if (Math.abs(bytes) < thresh) {
				return bytes + ' B';
			}

			const units = si ?
				['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] :
				['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


			return bytes.toFixed(dp) + ' ' + units[u];
		},
		
		generateUID( ts = true) {
			// https://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-jsfunction generateUID() {
			// I generate the UID from two parts here 
			// to ensure the random number provide enough bits.
			// And Kristoffer has added a timestamp for meta...
			var timestamp = new Date().getTime();
			var firstPart = (Math.random() * 46656) | 0;
			var secondPart = (Math.random() * 46656) | 0;
			firstPart = ("000" + firstPart.toString(36)).slice(-3);
			secondPart = ("000" + secondPart.toString(36)).slice(-3);
			
			if( ts ) {
				return timestamp +'.'+ firstPart +'.'+ secondPart;
			}
			else {
				return firstPart +'.'+ secondPart;
			}
		},
		
		// files
		async filesUpload( file ) {
			const response = await this.$axios.post(process.env.VUE_APP_API_ENDPOINT + '/' + this.accountId + '/files', file);
			return response.data;
		},
		
		// async filesDelete(fileId) {
		//     const response = await this.$axios.delete(process.env.VUE_APP_API_ENDPOINT + '/'+this.accountId+'/files/' + fileId);
		//     return response.data;
		// },
		
		
		fileToUrl( file ){
			return process.env.VUE_APP_FILES_ENDPOINT + '/'+file.accountId+'/' + file.fileId +'.'+ file.extension;
		},
		
		fileKeyToUrl( key ){
			return process.env.VUE_APP_FILES_ENDPOINT + '/' + key;
		},
		
		getUserByUsername( username ){
			try {
				const users = this.$store.getters.getUsers;
				if (!users) {
					return null;
				}
				return users.find( u => u.username == username ) || {username, email: null,};
			} 
			catch (e) {
				console.log('getUserByUsername error');
				console.error(e);
				return
			}
		},
		
		usernameToName( username ){
			try {
				const users = this.$store.getters.getUsers;
				if (!users) {
					return null;
				}
				
				const user = users.find( u => u.username == username );
				

				if (
					user && 
					(user.given_name || user.family_name ) ){
					
					return user.given_name +' '+ user.family_name;
				}
				
				return user.email;
			} 
			catch (e) {
				console.log('usernameToName error');
				console.error(e);
				return
			}
		},
	},
}
