<template>
	<div 
		class="w-full h-full bg-white flex flex-row">
		<!-- :class="inApp || inPortal ? 'flex-col' : 'flex-row'"> -->  
		
		<NavigationAdmin 
			v-if="inAdmin"
			class="flex-none flex"
			:showKudosGive="showKudosGive"
			@setShowKudosGive="showKudosGive = $event"
		/>
		
		<NavigationApp
			class="flex-none flex"
			v-if="inApp"
			:showKudosGive="showKudosGive"
			@setShowKudosGive="showKudosGive = $event"
		/>
		
		<NavigationPortal
			class="flex-none flex"
			v-if="inPortal"
			:showKudosGive="showKudosGive"
			@setShowKudosGive="showKudosGive = $event"
		/>
		
		
		
		<!-- <div 
			v-if="isMobile"
			class="bg-yellow-400 fixed top-0 right-0 p-8">
			IS MOBILE!!!
		</div> -->
		
		<!-- :class="!isMobile ? 'flex-row' : 'flex-col' "> -->
		<!-- 
		<NavigationLeft 
			v-show="inAdmin"
			v-if="!isMobile"
			class="flex-none flex"
		/> -->
		
		<!-- <div 
			class="fixed bottom-0 right-0 h-8 flex text-white font-bold text-center"
			style="z-index: 1000;">
			
			<div class="w-16 hidden sm:block bg-yellow-500">sm</div>
			<div class="w-16 hidden md:block bg-red-500">md</div>
			<div class="w-16 hidden lg:block bg-blue-500">lg</div>
			<div class="w-16 hidden xl:block bg-gray-500">xl</div>
			<div class="w-16 hidden 2xl:block bg-orange-500">2xl</div>
		</div> -->
	   
		
		<div class="flex-grow overflow-hidden h-full relative">
			<router-view id="routerView" />
		</div>
		
		<div 
			v-if="showKudosGive && accountId && (inAdmin || inApp)" 
			class="flex-none overflow-hidden h-full relative">
			
			<KudosGive 
				@setShowKudosGive="showKudosGive = $event"
			/>
		</div>
		<div 
			v-if="showKudosGive && accountId && (inPortal)" 
			class="flex-none overflow-hidden h-full relative">
			
			<KudosGive
				@setShowKudosGive="showKudosGive = $event"
			/>
		</div>
		
		<!-- <NavigationBottomMobile 
			v-show="inAdmin"
			v-if="isMobile"
			class="flex-none bg-black"
			style="z-index: 1000; box-shadow: 0 -5px 10px rgba(0,0,0,.03);"
		/> -->
		
		<notifications 
			classes="notification" 
			position="top center"
		/>
		
		<Confetti v-if="showConfetti" />
		
		<KudoNotice v-if="kudoNotice" />
		
		<!-- <div
			v-if="appVersion && appVersion.version && appVersion.stage == 'dev'"
			class="fixed top-0 right-0 mt-4 mr-4  text-xs pointer-events-none bg-orange-500 text-white px-1 rounded">
			
			DEV v{{appVersion.version}}
		</div> -->
		
		
		<div 
			v-show="showDisconnected"
			class="fixed top-0 right-0 m-4"
			style="z-index: 1001;">
			
			<transition 
				v-show="showDisconnected"
				name="pop"
				appear>
				
					<svg 
						width="55" height="49" viewBox="0 0 55 49" fill="none" xmlns="http://www.w3.org/2000/svg"
						class="w-12 h-12 overflow-hidden rounded-full bg-white p-2">
						
						<path d="M48.0604 0.112305L41.5821 7.67037C27.8456 1.23202 11.5298 4.52118 0.432617 17.4679L4.27163 21.9467C13.2093 11.5894 26.0461 8.44017 37.3232 12.6391L32.8843 17.8178C24.2465 15.7183 14.8889 18.5876 8.17064 26.4955L12.0097 30.9744C16.3885 25.8657 22.207 23.3464 28.0256 23.5563L21.4272 31.2543C19.3878 32.1641 17.5282 33.5637 15.8487 35.5232L16.8084 36.643L9.85021 44.6209L11.9497 47.0703L50.1599 2.56168L48.0604 0.112305Z" fill="black"/>
						<path d="M23.5269 44.4808L27.3659 48.9597L31.2049 44.4808C29.1054 41.9615 25.6263 41.9615 23.5269 44.4808V44.4808Z" fill="black"/>
						<path d="M25.3262 36.5029C28.7453 35.7331 32.4643 36.9228 35.1037 40.002L38.9427 35.5232C36.6033 32.7939 33.664 31.0443 30.6648 30.3445L25.3262 36.5029Z" fill="black"/>
						<path d="M35.0439 25.1659C37.8632 26.4256 40.5025 28.3151 42.782 30.9744L46.621 26.4956C44.4015 23.9062 41.8822 21.8068 39.1829 20.3372L35.0439 25.1659V25.1659Z" fill="black"/>
						<path d="M47.2207 10.9595L43.2617 15.5783C45.8411 17.2579 48.3004 19.3573 50.5199 21.9467L54.3589 17.4678C52.1394 14.9485 49.7401 12.779 47.2207 10.9595Z" fill="black"/>
					</svg>
			</transition>
		</div>
		
		<div 
			v-show="showDisconnected"
			class="fixed bg-beige-dark inset-0 flex items-center justify-center">
			
			<div class="text-center">
				<p>Du har mistet Internett-forbindelsen</p>
				
				<button
					class="button submit mt-6"
					@click="pageRefresh()">
					
					Prøv å laste inn på nytt
				</button>
			</div>
		</div>
		
		<div v-show="showAddToHomeScreen">
			<div 
				v-if="isIos() == 'ipad' || isIos() == 'iphone'"
				class="fixed left-0 right-0 flex items-center justify-center m-8"
				:class="isIos() == 'ipad' ? 'top-0' : 'bottom-0'"
				style="z-index: 1000; ">
				
				<div 
					class="bg-white p-8 rounded-lg flex flex-col items-center justify-center relative"
					style="box-shadow: 0 0 20px rgba(0,0,0,.5);">
						
					<div 
						class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
						@click="onCloseAddToHomeScreen()">
						
						<i class="fas fa-times"></i>
					</div>
					
					<div class="flex flex-col items-center">
						<div>
							<img 
								src="/android-chrome-192x192.png"
								class="w-16 h-16 bg-gray-darkest rounded-xl p-2 my-2"
							/>
						</div>
						<div class="text-left">
							<h2 class="text-black text-center">Install App on {{ isIos() == 'ipad' ? 'IPad' : 'IPhone' }}</h2>
							
							<div class="flex mt-4">
								<strong class="font-bold w-6">1</strong>
								<div>
									Tap <span class="w-4 h-4 inline-flex items-center justify-center">
									<svg viewBox="0 0 1000 1000" class="w-full"><path fill="royalblue" d="M780,290H640v35h140c19.3,0,35,15.7,35,35v560c0,19.3-15.7,35-35,35H220c-19.2,0-35-15.7-35-35V360c0-19.2,15.7-35,35-35h140v-35H220c-38.7,0-70,31.3-70,70v560c0,38.7,31.3,70,70,70h560c38.7,0,70-31.3,70-70V360C850,321.3,818.7,290,780,290z M372.5,180l110-110.2v552.7c0,9.6,7.9,17.5,17.5,17.5c9.6,0,17.5-7.9,17.5-17.5V69.8l110,110c3.5,3.5,7.9,5,12.5,5s9-1.7,12.5-5c6.8-6.8,6.8-17.9,0-24.7l-140-140c-6.8-6.8-17.9-6.8-24.7,0l-140,140c-6.8,6.8-6.8,17.9,0,24.7C354.5,186.8,365.5,186.8,372.5,180z"/></svg></span> located here at the {{ isIos() == 'ipad' ? 'top' : 'bottom' }}
								</div>
							</div>
							<div class="flex">
								<strong class="font-bold w-6">2</strong>
								<div>
									<span v-if="isIos() == 'iphone'">Swipe down.</span>
									Tap <span class="text-black">Add to Home Screen</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div 
				v-else-if="!showAppInstalled && isMobile"
				class="fixed bottom-0 left-0 right-0 flex items-center justify-center m-8"
				style="z-index: 1000; ">
				
				<div 
					class="bg-white p-8 rounded-lg flex flex-col items-center justify-center relative"
					style="box-shadow: 0 0 20px rgba(0,0,0,.5);">
					
					<div 
						class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
						@click="onCloseAddToHomeScreen()">
						
						<i class="fas fa-times"></i>
					</div>
					
					<div class="flex items-center">
						<img 
							src="/android-chrome-192x192.png"
							class="w-16 h-16 flex-none bg-gray-darkest rounded-xl p-2"
						/>
					</div>
					<div class="mt-2 text-center">
						<h2 class="text-black">Install App</h2>
						Add icon to your home screen
						 <!-- and <br>receive push notifications -->
					</div>
					<button
						class="button submit mt-6"
						@click="addToHomeScreen()">
						
						Add to Home Screen
					</button>
				</div>
			</div>
		</div>
		
		<div 
			v-show="showAppInstalled "
			class="fixed bg-white p-12 bottom-0 left-0 right-0 flex flex-col border-t"
			style="z-index: 1001; box-shadow: 0 -10px 10px rgba(0,0,0,.1);">
			
			<div 
				class="close text-gray hover:text-black absolute top-0 right-0 mt-4 mr-6" 
				@click="showAppInstalled = false; onCloseAddToHomeScreen()">
				
				<i class="fas fa-times"></i>
			</div>
			
			<div class="flex flex-col items-center text-center">
				<strong class="text-black">Upsell er installert</strong>
				<div>Du finner Upsell som ikon på hjemskjermen på din enhet.</div>
			</div>
		</div>
		
		<!-- <div
			v-if="version"
			class="fixed bottom-0 right-0 bg-gray-dark px-2 m-1 rounded text-white text-sm opacity-0">
			
			v{{version}}
		</div> -->
		
		<MessageList 
			v-if="accountId && !inAdmin && !inPortal"
			:inPortal="inPortal"
			context="app"
		/>
	</div>
</template>

<script>
	// let deferredPrompt;
	
	export default {
		name: 'App',
		components: {
			'NavigationAdmin': () => import('@/components/NavigationAdmin.vue'),
			'NavigationApp': () => import('@/components/NavigationApp.vue'),
			'NavigationPortal': () => import('@/components/NavigationPortal.vue'),
			'MessageList': () => import('@/components/messages/MessageList.vue'),
			'KudoNotice': () => import('@/components/KudoNotice.vue'),
			'KudosGive': () => import('@/components/KudosGive.vue'),
			// 'KudosGiveInPortal': () => import('@/components/KudosGiveInPortal.vue'),
			// 'NavigationBottomMobile': () => import('@/components/NavigationBottomMobile.vue'),
		},
		
		computed: {
			inAdmin(){
				if (this.$route && this.$route.path.startsWith('/admin/') ) {
					return true;
				}
				return false;
			},
			
			inApp(){
				if (this.$route && this.$route.path.startsWith('/app/') ) {
					return true;
				}
				return false;
			},
			
			inPortal(){
				if (this.$route && this.$route.path.startsWith('/portal/') ) {
					if (this.$route.name == 'portal-signin') {
						return false;
					}
					return true;
				}
				return false;
			},
			
			// wSConnectionLastPong(){
			//     return this.$store.getters.getWSConnectionLastPong;
			// },
			
			showDisconnected(){
				return this.$store.getters.getShowDisconnected;
			},
			
			showConfetti(){
				return this.$store.getters.getShowConfetti;
			},
			
			kudoNotice(){
				return this.$store.getters.getKudoNotice;
			},
			
			// showKudosGive(){
			// 	return this.$store.getters.getShowKudosGive;
			// },
			
			// isMobile(){
			//     return this.$store.getters.getIsMobile;
			// }
		},
		
		data () {
			return {
				// windowWidth: window.innerWidth,
				appVersion: null,
				showAddToHomeScreen: false,
				// swRegistration: null,
				// showInstallPromotion: false,
				showAppInstalled: false,
				deferredPrompt: null,
				
				showKudosGive: false,
				// notificationPermission: null,
				// isMobile: false,
			}
		},
		
		methods: {
			// onRefreshAll(){
			//     if ('serviceWorker' in navigator) {
			//         navigator.serviceWorker.getRegistrations().then( registrations => {
			//             for(let registration of registrations) {
			//                 registration.unregister()
			//             }
			//         });
			//     }
			// 
			//     location.reload();
			// },
			// 
			
			onCloseAddToHomeScreen() {
				this.showAddToHomeScreen = false;
				localStorage.showAddToHomeScreen = 'false';
				console.log('closed... this.showAddToHomeScreen', this.showAddToHomeScreen);
			},
			
			pageRefresh(){
				this.$router.go( this.$router.currentRoute );
			},
			
			async addToHomeScreen(){
				// Installation must be done by a user gesture! Here, the button click
				// hide our user interface that shows our A2HS button
				// Show the prompt
				this.deferredPrompt.prompt();
				// Wait for the user to respond to the prompt
				const choiceResult = await this.deferredPrompt.userChoice;
			
				if ( choiceResult.outcome === 'accepted' ) {
					console.log('User accepted the A2HS prompt');
					// this.showInstallPromotion = false;
				}
				else {
					console.log('User dismissed the A2HS prompt');
				}
			
				this.deferredPrompt = null;
			},
			
			isIos() { // Detects if device is on iOS 
				const userAgent = window.navigator.userAgent.toLowerCase();
				
				if ( /iphone|ipod/.test( userAgent ) ) {
					return 'iphone'
				}
				else if ( /ipad/.test( userAgent ) ) {
					return 'ipad'
				}
				else if ( (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad' ) {
					return 'ipad'
				}
				else {
					return
				}
			},
			
			isInStandaloneMode() { // Detects if device is in standalone mode
				// return false
				
				if (
					'standalone' in window.navigator && 
					window.navigator.standalone ){
				
					return true
				}
			},
			
			checkIfMobile(){
				// console.log('checkIfMobile');
				
				if ( window.innerWidth < 640 ) {
					// this.isMobile = true;
					this.$store.commit('setIsMobile', true);
					// console.log('mobile true');
				}
				else {
					this.$store.commit('setIsMobile', false);
					// console.log('mobile false');
				}
			},
		},
		
		async created() {
			// Checks if should display install popup notification:
			if ( 
				this.isIos() && 
				!this.isInStandaloneMode() ) {
				
				if ( localStorage.showAddToHomeScreen && localStorage.showAddToHomeScreen == 'false' ) {
					// has been closed before. do not continue to show.
				}
				else {
					this.showAddToHomeScreen = true;
				}
			}
			
			if ('serviceWorker' in navigator) {
				// Register a service worker hosted at the root of the site using the default scope.
				try {
					// this.swRegistration = await navigator.serviceWorker.register('/sw.js?v='+Math.random()+1);
					this.swRegistration = await navigator.serviceWorker.register('/sw.js?v='+process.env.VUE_APP_VERSION);
					// console.log('Service worker registration succeeded:', this.swRegistration);
				}
				catch (error) {
					// console.log('Service worker registration failed:', error);
				}
			}
			else {
				// console.log('Service workers are not supported.');
			}
			
			window.addEventListener('beforeinstallprompt', (event) => {
				// console.log('show beforeinstallprompt');
				// console.log(event);
				
				if ( localStorage.showAddToHomeScreen && localStorage.showAddToHomeScreen == 'false' ) {
					// has been closed before. do not continue to show.
				}
				else {
					this.showAddToHomeScreen = true;
				}
				
				
				event.preventDefault();
				this.deferredPrompt = event;
			});
			
			window.addEventListener('appinstalled', (event) => {
				// this.addToHomeScreen = false;
				this.showAddToHomeScreen = false;
				this.showAppInstalled = true;
				// Log install to analytics
			
				// console.log('show appinstalled');
				// console.log(event);
				// alert('Install was successfull');
				// event.preventDefault();
			});
			
			window.addEventListener('offline', (event) => { 
				console.log('offline');
				
				this.$store.commit('setShowDisconnected', {
					message: 'youAreOffline',
				});
			});
			
			window.addEventListener('online', (event) => { 
				console.log('online');
				
				this.$store.commit('setShowDisconnected', null);
			});
			
			window.addEventListener('resize', (event) => { 
				this.checkIfMobile();
			});
			
			this.appVersion = {
				version: process.env.VUE_APP_VERSION,
				stage: process.env.NODE_ENV == 'development' ? 'dev' : '',
			};
			
			
			this.checkIfMobile();
			
		},
		
		beforeDestroy() {
			// console.log('beforeDestroy');
			// try {
			//     // clearInterval( this.pollingMessages );
			//     // clearInterval( this.pollingOffers );
			//     // clearInterval( this.pollTrackings );
			//     // 
			//     // clearInterval( this.pollWebSocketConnection );
			//     clearInterval( this.pollingWebSocketConnection );
			// } 
			// catch (e) {
			//     console.log('Could not clear polling');
			//     console.error(e);
			// }
		},
	}
</script>
